import React, { useState, useContext } from "react";
import { LockClosedIcon } from '@heroicons/react/solid'
import GetCustomerCode from "./GetCustomerCode";
import LoginToWebshop from "../components/LoginToWebshop";
import { Text, LanguageContext } from '../languages/Language';
import { ReactComponent as LogoutIcon } from '../assets/pic/logout.svg';
import logo from '../assets/pic/logo2.png';
import { VERSION } from '../constants';
import fetchItemsFromAPI from "../fetch/fetchItemsFromAPI";
import fetchDataFromAPI from "../fetch/useFetchDataFromAPI";
import ReCAPTCHA from "react-google-recaptcha";

//import ReactDOM from "react-dom";
//import PropTypes from 'prop-types';

function setToken() {
}

function getToken() {
    const tokenString = sessionStorage.getItem('token');
    return tokenString;
}

export default function LoginModal({setShowRegisterModal,onLogoutDeleteItemsAndCategories, onGetCategories, onGetItems, parentHandleToken, children }) {
    const [customerCode, setCustomerCode] = useState("");
    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");
    const [showModall, setshowModall] = React.useState(false);
    const [isSubmitted, setIsSubmitted] = useState(false);
    const languageContext = useContext(LanguageContext);

    var token = getToken();

    const switchRegister=()=>{
        setshowModall(false);
        setShowRegisterModal(true);
    }

    const [verified, setVerified] = useState(false);

    const onChange = (value) => {
      setVerified(true);
    };


    function handleLogout() {
        window.location.reload();
        setCustomerCode("")
        setEmail("")
        setPassword("")
        sessionStorage.removeItem('token');
        sessionStorage.removeItem('discRate');
        sessionStorage.removeItem('slipRef');
        sessionStorage.removeItem('items');
        sessionStorage.removeItem('allCategories');
        sessionStorage.removeItem('user');
        sessionStorage.removeItem('email');
        sessionStorage.removeItem('customerCode');
        sessionStorage.removeItem('Expl1');
        sessionStorage.removeItem('Expl2');
        //localStorage.clear();
        setToken("");
        setIsSubmitted(false);
        parentHandleToken(false);
        onLogoutDeleteItemsAndCategories()
    }

    const handleSubmit = async event => {
        event.preventDefault();

        if (!verified) {
            alert("Please verify that you are not a robot.");
            return;
          }

        sessionStorage.setItem('email', email)
        sessionStorage.setItem('customerCode', customerCode)
        LoginToWebshop(customerCode, email, password)
            .then((json) => {
                if (json.resCode === 200) {
                    fetchDataFromAPI(1306) //service for categories
                        .then(() => {
                            console.log("fetch category when login")
                            onGetCategories()
                            fetchItemsFromAPI(json.dataSet[0]?.token) //service for GetBasket AND! service for Items.Get.Customer.Catalog
                                .then(() => {
                                    console.log("fetch items when login")
                                    onGetItems()
                                })
                        })
                }
                token = getToken();
                if (token) {
                    setIsSubmitted(true);
                    parentHandleToken(true);
                } else {
                }
            })
    };

    const renderForm = (
        <>
            <button
                className=" items-center mx-0
                justify-center
                text-base font-bold  hover:opacity-75"
                type="button"
                onClick={() => setshowModall(true)}
            >
                {children ? children :
                    <Text tid="Login" />}
            </button>
            {showModall ? (
                <>
                    <div
                        className="justify-center items-center flex overflow-x-hidden
                        overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none"
                    >
                        <div
                            className="relative  w-auto  my-6 mx-auto max-w-3xl text-slate-900"
                        >
                            {/*content*/}

                            <div
                                className="lg:p-8 border-0 rounded-lg shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none"
                            >
                                {/*header*/}
                                <div
                                    className="flex items-center justify-between p-5 border-b border-solid border-transparent rounded-t"
                                >
                                    <button
                                        className="flex items-center justify-center border-0 text-black opacity-50 float-right text-xl
                                        leading-none font-semibold outline-none focus:outline-none"
                                        onClick={() => setshowModall(false)}
                                    >
                                        <span
                                            className="bg-transparent h-7 w-7 text-xl inline-block align-text-top text-company-primary-color"
                                        >
                                            &#10006;
                                        </span>
                                    </button>
                                    <h3
                                        className="text-lg w-auto font-semibold text-company-primary-color"
                                    >
                                        <Text tid="SignIn" />
                                    </h3>

                                </div>
                                {/*body*/}
                                <div
                                    className="relative flex-auto p-6"
                                >
                                    <div
                                        className="mb-4 text-slate-500 text-lg leading-relaxed">
                                        { /*----------------------------------------------------------------------------*/}

                                        <div className="h-auto flex flex-row items-center justify-center py-1 px-1 sm:px-1 lg:px-1">

                                            <div className="flex flex-col self-center space-y-1 justify-center">
                                                <div className="w-[325px] justify-center items-center">
                                                <div className="justify-center items-center rounded-sm p-4">
                                                   <center><img className="" src={logo} alt="Logo"/> </center>
                                                </div>
                                                </div>

                                                <form
                                                    className="mt-1 space-y-6 "
                                                    action="#"
                                                    method="POST"
                                                    onSubmit={handleSubmit}>
                                                    <input
                                                        type="hidden"
                                                        name="remember"
                                                        defaultValue="true"
                                                    />
                                                    <div
                                                        className="border"
                                                    >
                                                        <div className="px-2.5 py-1">
                                                            <label
                                                                id="labelCustomerCode"
                                                                htmlFor="customerCode"
                                                                className="sr-only"
                                                            >
                                                                <Text tid="CustomerCode" />
                                                            </label>
                                                            <div
                                                                className="bg-white border-white focus:outline-none focus:ring-company-primary-color focus:outline-none
                                                                 focus:z-10
                                                                   flex flex-row items-center"
                                                            >
                                                                <input
                                                                    onChange={(e) => setCustomerCode(e.target.value)}
                                                                    value={customerCode}
                                                                    id="customerCode"
                                                                    name="customerCode"
                                                                    type="text"
                                                                    autoComplete="customerCode"
                                                                    required
                                                                    className="appearance-none rounded-none relative block w-full px-3 py-2 border-none
                                                                      sm:text-sm
                                                                     focus:outline-none
                                                                 focus:z-10"
                                                                    placeholder={languageContext.dictionary["CustomerCode"]}
                                                                />
                                                                <div className="divide-none">
                                                                <GetCustomerCode
                                                                    email={email}
                                                                    password={password}
                                                                    getCustomerCode={(code) => setCustomerCode(code)}
                                                                />
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="px-2.5">
                                                            <label
                                                                htmlFor="email-address" className="sr-only"
                                                            >
                                                                <Text tid="EmailAddress" />
                                                            </label>
                                                            <input
                                                                onChange={(e) => setEmail(e.target.value)}
                                                                id="email-address"
                                                                name="email"
                                                                type="email"
                                                                autoComplete="email"
                                                                required
                                                                className="appearance-none rounded-none relative block w-full px-3 py-2 border-none
                                                                      sm:text-sm
                                                                     focus:outline-none
                                                                 focus:z-10"
                                                                placeholder={languageContext.dictionary["EmailAddress"]}
                                                            />
                                                        </div>
                                                        <div className="px-2.5">
                                                            <label htmlFor="password" className="sr-only">
                                                                <Text tid="Password" />
                                                            </label>
                                                            <input
                                                                onChange={(e) => setPassword(e.target.value)}
                                                                id="pass"
                                                                name="pass"
                                                                type="password"
                                                                autoComplete="current-password"
                                                                required
                                                                className="appearance-none rounded-none relative block w-full px-3 py-2 border-none
                                                                sm:text-sm
                                                               focus:outline-none
                                                           focus:z-10"
                                                                placeholder={languageContext.dictionary["Password"]}
                                                            />
                                                        </div>
                                                        <div className="py-1 flex justify-center">
                                                        <ReCAPTCHA
                                                         sitekey="6LfBMQsqAAAAAAcQ8BZ0gAgZ3Jaexg8WUf6KzCAH"
                                                         onChange={onChange}
                                                             />
                                                                </div>
                                                    </div>
                                                    <div>
                                                        <button
                                                            type="submit"
                                                            className="border-y-2 border-x-2 rounded-sm  border-company-primary-color group relative w-full flex justify-center
                                                            font-medium bg-transparent hover:bg-company-primary-color focus:outline-none focus:ring-2
                                                            focus:ring-offset-2 text-company-primary-color hover:text-white focus:ring-company-primary-color items-center px-1 py-1 text-base shadow-sm hover:opacity-75"                                                        >
                                                            <span
                                                                className="absolute left-0 inset-y-0 flex items-center pl-3"
                                                            >
                                                                <LockClosedIcon
                                                                    className="h-5 w-5 text-company-primary-color group-hover:text-red-100"
                                                                    aria-hidden="true" />
                                                            </span>
                                                            <Text tid="SignIn" />
                                                        </button>
                                                    </div>
                                                    <div className="flex flex-row justify-between">
                                                    <div className="text-company-primary-color h-1 text-xxs text-center">
                                                        {VERSION}
                                                    </div>
                                                    {
                                                        /*
                                                        <button onClick={() => switchRegister()}
                                                    className=" text-company-primary-color h-1 text-xxs text-center"><Text tid="Register" />
                                                    </button>
                                                        */
                                                    }

                                                    </div>

                                                </form>
                                            </div>

                                        </div>
                                        {/*-----------------------------------------------------------------------------------------*/}
                                    </div>
                                </div>
                                {/*footer*/}

                            </div>
                        </div>
                    </div>
                    <div
                        className="opacity-50 fixed inset-0 z-40 bg-black"
                    >
                    </div>
                </>
            ) : null}
        </>
    );

    const renderLogout = (
        <button
            className="flex w-28 lg:w-32 items-center
            rounded-md border border-transparent
            text-sm shadow-sm hover:opacity-75"
            type="button"
            onClick={() => handleLogout()}
        >
            <LogoutIcon className="w-6 h-6 fill-company-primary-color mx-1" />
            <Text tid="SignOff" />
        </button>
    );

    return (
        <div>
            {
                isSubmitted
                    ? renderLogout
                    : sessionStorage.getItem('token')
                        ? renderLogout
                        : renderForm
            }
        </div>
    );
}

